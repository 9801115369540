import React, { useState, useEffect, useCallback } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  InputLabel,
  Box,
  TextField,
  Typography,
  Divider,
  Container,
  FormHelperText,
  FormControlLabel,
  FormLabel,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import CustomCheckboxGroup from "./checkboxGroup";
import SubirFotos from "./upload_photo";
import Areasubida from "./upload_file";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import editarElemento from "../APIS/editarElemento";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AddLocationAlt as AddLocationAltIcon } from "@mui/icons-material";

const theme = createTheme();

const WhatsAppStepperForm2 = ({
  token,
  apiData,
  dataDepartamentos,
  dataCategorias,
  dataFrecuencia,
  dataFrecuencia_pago,
  ulrbase,
}) => {
  const cleanString = (str) => {
    // Elimina caracteres invisibles y no imprimibles
    return str.replace(/[^\x20-\x7E]/g, "");
  };

  const unidadMedidaLimpia = cleanString(apiData.producto.unidad_medida || "");
  //--------------------------INICIO VALORES POR DEFECTO DEL FORMULARIO--------------------------

  const defaultValues = {
    id: apiData.producto.id || "",
    categoria: apiData.producto.categoria || "",
    nombre_producto: apiData.producto.nombre_producto || "",
    descripcion: apiData.producto.descripcion || "",
    unidad_medida: unidadMedidaLimpia || "",
    iva: apiData.producto.iva || "",
    forma_pago: [],
    fotos: apiData.producto.fotos || [],
    ficha_tecnica: apiData.producto.ficha_tecnica || "",
    departamento: "",
    municipio: "",
    minimo_despacho: "",
    maximo_despacho: "",
    precio_minimo_venta: "",
    entrega_punto_a_punto: "",
    frecuencia_venta: [],
  };
  const initialDispatches = apiData.producto.despachos || []; // Despachos iniciales
  // console.log("Despachos iniciales:", initialDispatches);

  //----------------------------FIN VALORES POR DEFECTO DE FORMULARIO--------------------------

  //------------------------INICIO MANEJO CAMPO SELECT PASO 1-------------------------

  /*
    opcionesCategorias: Array de objetos con las categorías disponibles
    Se obtienen todas las categorias que vienen de la API y se limpian los datos,
    en este caso la clave ID que contiene caracteres no deseados.

    useEffect: Se ejecuta cuando dataCategorias cambia y me devuelve un array limpio
  */
  const [opcionesCategorias, setCategoria] = useState([]); // Estado de todas las opciones de categorías
  const [idCategoriaSeleccionada, setCategoriaID] = useState(""); // Estado de la categoría seleccionada

  useEffect(() => {
    if (dataCategorias && Array.isArray(dataCategorias.categorias)) {
      const categoriasLimpias = dataCategorias.categorias.map((categoria) => ({
        ...categoria,
        id: categoria.id.replace(/^\ufeff/, ""), // Eliminar el carácter BOM si está presente
      }));
      setCategoria(categoriasLimpias);
    } else {
      console.error(
        "dataDepartamentos no es un array o no está disponible:",
        dataCategorias
      );
    }
  }, [dataCategorias]);

  useEffect(() => {
    const nombreCategoria = apiData.producto.categoria || "";
    const categoriaSeleccionada = opcionesCategorias.find(
      (categoria) => categoria.title === nombreCategoria
    );
    setCategoriaID(categoriaSeleccionada ? categoriaSeleccionada.id : "");
  }, [apiData, opcionesCategorias]);

  //Esta parte del código se ejecuta cuando el usuario selecciona una categoría y se actualiza el campo select.

  const handleCategoryChange = (event) => {
    const nuevaCategoria_id = event.target.value;
    setCategoriaID(nuevaCategoria_id);
    const categoriaSeleccionada = opcionesCategorias.find(
      (categoria) => categoria.id === nuevaCategoria_id
    );
    setValue(
      "categoria",
      categoriaSeleccionada ? categoriaSeleccionada.title : ""
    );
  };

  //------------------------FIN MANEJO CAMPO SELECT PASO 1-------------------------

  //----------------------------INICIO VALIDACIONES DE CAMPOS FORMULARIO----------------------------
  const schema = yup.object().shape({
    categoria: yup.string().required("La categoria es requerida"),
    nombre_producto: yup
      .string()
      .required("El nombre del producto es requerido"),
    descripcion: yup.string().required("Descripción es requerida"),
    unidad_medida: yup.string().required("La unida de medida es requerida"),
    iva: yup.string().required("El iva es requerido"),
    forma_pago: yup.array().min(1, "Debe seleccionar al menos una opción"),
    fotos: yup.array().min(1, "Debe subir al menos una foto"),
    ficha_tecnica: yup.string().nullable(),
    departamento: yup
      .string()
      .required("El departamento de venta es requerido"),
    municipio: yup.string().required("El municipio es requerido"),
    minimo_despacho: yup
      .string()
      .required("El mínimo de despacho es requerido")
      .matches(/^\d+$/, "El mínimo de despacho debe ser un número"),
    maximo_despacho: yup
      .string()
      .required("El maximo de despacho es requerido")
      .matches(/^\d+$/, "El maximo de despacho debe ser un número"),
    precio_minimo_venta: yup
      .number()
      .nullable(true)
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .typeError("El precio mínimo de venta debe ser un número"),

    entrega_punto_a_punto: yup.string().required("Este campo es requerido"),
    frecuencia_venta: yup
      .array()
      .min(1, "Debe seleccionar al menos una opción"),
  });

  //--------------------------------FIN VALIDACIONES DE CAMPOS FORMULARIO----------------------------

  const [activeStep, setActiveStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [departamentos, setDepartamentos] = useState([]);
  const [departamentoSeleccionado, setdepartamento_Seleccionado] = useState("");
  const [municipios, setMunicipios] = useState([]);
  const [undStates, setund] = useState("");
  const [unidadSeleccionada, set_unidadSeleccionada] = useState("");
  const [ivastates, setiva] = useState("");

  //-----------INICIO OBTENER DEPARTAMENTOS Y MUNICIPIOS PASO 3----------------

  /*
  Solo se ejecuta, cuando dataDepartamentos cambia y devuelve un array limpio,
  quitando el carácter BOM si está presente en el ID de cada departamento.
  */
  useEffect(() => {
    if (dataDepartamentos && Array.isArray(dataDepartamentos.departamentos)) {
      const departamentosLimpios = dataDepartamentos.departamentos.map(
        (departamento) => ({
          ...departamento,
          id: departamento.id.replace(/^\ufeff/, ""), // Eliminar el carácter BOM si está presente
        })
      );
      setDepartamentos(departamentosLimpios);
    } else {
      console.error(
        "dataDepartamentos no es un array o no está disponible:",
        dataDepartamentos
      );
    }
  }, [dataDepartamentos]);

  /*
  Este codigo se ejcuta cuando el usuario selecciona un departamento y utiliza el id
  del departamento seleccionado para obtener los municipios correspondientes,
  de esta forma se mostraran los municipios de acuerdo al departamento.
  */
  const handleDepartmentChange = async (event) => {
    const departamento_id = event.target.value;
    setdepartamento_Seleccionado(departamento_id);
    setValue("municipio", "");

    try {
      const response = await fetch(
        `${ulrbase}/obtenerMunicipios?departamento=${departamento_id}`
      );
      const data = await response.json();
      if (Array.isArray(data.municipios)) {
        setMunicipios(data.municipios);
      } else {
        console.error(
          "Error fetching municipios data: data is not an array",
          data
        );
        setMunicipios([]);
      }
    } catch (error) {
      console.error("Error fetching municipios data:", error);
      setMunicipios([]);
    }
  };
  //----------------------------FIN OBTENER DEPARTAMENTOS Y MUNICIPIOS PASO 3----------------

  //----------------------------INICIO ESTADO CAMPO SELECT PASO 3----------------------------

  const [despachos, setDespacho] = useState(initialDispatches);
  // console.log("Despachos:", despachos);

  /*
  Se utiliza para almacenar los valores de los campos del formulario de despachos pero vacíos.
  Esto se hace cuando el usuario presiona el botón de agregar despacho y se limpian los campos.
  */
  const [nuevoDespacho, set_nuevoDespacho] = useState({
    //<----ESTADO DE NUEVO DESPACHO
    departamento: "",
    municipio: "",
    minimo_despacho: "",
    maximo_despacho: "",
    precio_minimo_venta: "",
    forma_pago: [],
    frecuencia_venta: [],
    entrega_punto_a_punto: "",
  });
  const [despachoSeleccionado, set_despachoSeleccionado] = useState(null);

  const liimpiarSelect = () => {
    set_despachoSeleccionado(null);
  };

  //----------------------------FIN ESTADO CAMPO SELECT PASO 3----------------------------

  //----------INICIO OBTENER TODOS LOS MUNICIPIOS PARA MOSTRAR LOS NOMBRES EN EL CAMPO SELECT DEL PASO 3----------------

  /*
  Se realiza una petición a la API para obtener los municipios de un departamento específico.
  1 - Se obtienen los IDs de los departamentos de los despachos.
  2 - Se realiza una petición por cada ID de departamento para obtener los municipios.
  3 - Se obtienen los nombres de los municipios y se almacenan en un objeto.
  4 - Se actualiza el estado allmunicipios con todos los municipios.

  En el useEffect que llama a fetchAllMunicipios, se realizan
  múltiples llamadas a la API para obtener los municipios de todos los departamentos
  que están en los despachos.
  */
  const [allmunicipios, setAllmunicipios] = useState([]);
  const fetchMunicipios = useCallback(
    async (departamento_id) => {
      try {
        const response = await fetch(
          `${ulrbase}/obtenerMunicipios?departamento=${departamento_id}`
        );
        const data = await response.json();
        if (Array.isArray(data.municipios)) {
          return data.municipios;
        } else {
          console.error(
            "Error fetching municipios data: data is not an array",
            data
          );
          return [];
        }
      } catch (error) {
        console.error("Error fetching municipios data:", error);
        return [];
      }
    },
    [ulrbase]
  );

  useEffect(() => {
    const fetchAllMunicipios = async () => {
      const departamentosIDs = [
        ...new Set(despachos.map((despachos) => despachos.departamento)),
      ];
      // console.log("IDs de departamentos:", departamentosIDs); // [ "1", "1" ]

      const municipiosPromises = departamentosIDs.map((id) =>
        fetchMunicipios(id)
      );
      const municipiosResults = await Promise.all(municipiosPromises);
      const allMunicipios = municipiosResults.flat();
      setAllmunicipios(allMunicipios);
    };

    fetchAllMunicipios();
  }, [despachos, fetchMunicipios]);

  const municipioNames = {};
  allmunicipios.forEach((municipio) => {
    municipioNames[municipio.id] = municipio.nombre;
  });

  //------------------------FIN OBTENER TODOS LOS MUNICIPIOS PARA MOSTRAR LOS NOMBRES EN EL CAMPO SELECT DEL PASO 3----------------

  const methods = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    setValue, // Función para actualizar valores
    getValues, // Función para obtener valores
    watch,
    control,
    formState,
    trigger, // Función para disparar validación
    clearErrors,
    setError, // Función para establecer errores
  } = methods;
  const { errors } = formState;

  const watchedValues = watch();
  // console.log("Datos formulario:", watchedValues);

  useEffect(() => {
    const values = getValues();
    setValue("categoria", values.categoria);
    setValue("forma_pago", values.forma_pago);
    setValue("fotos", values.fotos);
    setValue("ficha_tecnica", values.ficha_tecnica);
  }, [activeStep, setValue, getValues]);

  const steps = ["Paso 1", "Paso 2", "Paso 3"];

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { id } = defaultValues;
  const producto = {
    id,
    categoria: watchedValues.categoria,
    nombre_producto: watchedValues.nombre_producto,
    descripcion: watchedValues.descripcion,
    unidad_medida: watchedValues.unidad_medida,
    iva: watchedValues.iva,
    ficha_tecnica: watchedValues.ficha_tecnica,
    celular_cliente: apiData.producto.celular_cliente,
  };
  // console.log("Producto:", producto);

  const producto_fotos = {
    id,
    fotos: watchedValues.fotos,
    celular_cliente: apiData.producto.celular_cliente,
  };

  const producto_despachos = {
    id,
    despachos: despachos,
    celular_cliente: apiData.producto.celular_cliente,
  };
  // console.log("Producto despachos:", producto_despachos);
  // console.log("Producto fotos:", producto_fotos);
  const url_paso1 = `${ulrbase}/editarProductoUno`;
  const url_paso2 = `${ulrbase}/editarProductoDos`;
  const url_paso3 = `${ulrbase}/editarProductoTres`;
  const token_producto = token;

  //Logica boton Guardar y continuar.
  /*
  Este boton valida que los campos del paso 1 y 2 esten completado
  */
  const handleNext = async () => {
    let fieldsToValidate = [];

    if (activeStep === 0) {
      fieldsToValidate = [
        "categoria",
        "nombre_producto",
        "descripcion",
        "unidad_medida",
        "iva",
        "ficha_tecnica",
      ];
    } else if (activeStep === 1) {
      fieldsToValidate = ["fotos"];
    }
    const isValid = await trigger(fieldsToValidate);
    if (isValid) {
      setIsLoading(true);
      if (activeStep === 0) {
        const { data, loading, error } = await editarElemento(
          url_paso1,
          token_producto,
          producto
        );
        if (data && !loading) {
          // console.log("Operación exitosa", data);
          setIsLoading(false);
        }
        if (error) {
          console.error("Error en la operación:", error);
          setIsLoading(false);
          return;
        }
        if (!loading) {
          setIsLoading(false);
          if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      } else if (activeStep === 1) {
        const { data, loading, error } = await editarElemento(
          url_paso2,
          token_producto,
          producto_fotos
        );
        if (data && !loading) {
          // console.log("Operación exitosa", data);
          setIsLoading(false);
        }
        if (error) {
          console.error("Error en la operación:", error);
          setIsLoading(false);
          return;
        }
        if (!loading) {
          setIsLoading(false);
          if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      } else if (activeStep === 2) {
        const { data, loading, error } = await editarElemento(
          url_paso3,
          token_producto,
          producto_despachos
        );
        if (data && !loading) {
          // console.log("Operación exitosa", data);
          setIsLoading(false);
          setShowSuccess(true);
        }
        if (error) {
          console.error("Error en la operación:", error);
          setIsLoading(false);
          return;
        }
        if (!loading) {
          setIsLoading(false);
          if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }
    }
  };

  const handleBack = () => {
    clearErrors();
    if (subStep === 1) {
      setSubStep(0);
      setValue("departamento", "");
      setdepartamento_Seleccionado("");
      setValue("municipio", "");
      setValue("minimo_despacho", "");
      setValue("maximo_despacho", "");
      setValue("precio_minimo_venta", "");
      setValue("forma_pago", []);
      setValue("frecuencia_venta", []);
      setValue("entrega_punto_a_punto", "");
    } else if (activeStep <= 2) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
    // Aquí puedes añadir cualquier lógica adicional después de cerrar el mensaje de éxito
  };

  // console.log("info Producto:", producto);
  //----------------OPCIONES DE FRECUENCIA DE PAGO PASO 3 ---------

  const [paymentOptions, setSalesFrequency_payOptions] = useState([]);

  useEffect(() => {
    const frecuencias_pago = (dataFrecuencia_pago.frecuencias_pago || []).sort((a, b) =>{
      const order = ["15", "30", "45", "60", "Contra entrega"];
      return order.indexOf(a.id) - order.indexOf(b.id);
    });
    const mappedOptions = frecuencias_pago.map((frecuencia_pago) => ({
      value: frecuencia_pago.id,
      label: frecuencia_pago.title,
    }));

    setSalesFrequency_payOptions(mappedOptions);
  }, [dataFrecuencia_pago.frecuencias_pago]);

  //-----------------FIN OPCIONES DE FRECUENCIA DE PAGO PASO 3 ---------------

  // --------------OPCIONES DE FRECUENCIA DE VENTA PASO 3------------------
  const [sales_frequencyOptions, setSalesFrequencyOptions] = useState([]);

  useEffect(() => {
    const frecuencias = (dataFrecuencia.frecuencias || []).sort((a, b) => {
      const order = ["1", "2", "7", "15", "30", "45", "60"];
      return order.indexOf(a.id) - order.indexOf(b.id);
    });

    const mappedOptions = frecuencias.map((frecuencia) => ({
      value: frecuencia.id,
      label: frecuencia.title,
    }));

    setSalesFrequencyOptions(mappedOptions);
  }, [dataFrecuencia.frecuencias]);
  // -------------FIN OPCIONES DE FRECUENCIA DE VENTA PASO 3------------------

  // ------------------INICIO LOGICA DE CAMPO SELECT PASO 3------------------
  const agregarDespacho = () => {
    if (despachoSeleccionado !== null) {
      const newDispatchs = [...despachos];
      newDispatchs[despachoSeleccionado] = nuevoDespacho;
      setDespacho(newDispatchs);
      set_despachoSeleccionado(null);
    } else {
      setDespacho([...despachos, nuevoDespacho]);
    }
    set_nuevoDespacho({
      departamento: "",
      municipio: "",
      minimo_despacho: "",
      maximo_despacho: "",
      precio_minimo_venta: "",
      forma_pago: [],
      frecuencia_venta: [],
      entrega_punto_a_punto: "",
    });
    setValue("departamento", "");
    setdepartamento_Seleccionado("");
    setValue("municipio", "");
    setValue("minimo_despacho", "");
    setValue("maximo_despacho", "");
    setValue("precio_minimo_venta", "");
    setValue("forma_pago", []);
    setValue("frecuencia_venta", []);
    setValue("entrega_punto_a_punto", "");
  };

  const editarDespacho = (index) => {
    if (index !== null && index >= 0 && index < despachos.length) {
      const despacho = despachos[index];
      set_nuevoDespacho(despacho);
      set_despachoSeleccionado(index);

      setValue("departamento", despacho.departamento);
      setdepartamento_Seleccionado(despacho.departamento);

      // Filtrar municipios según el departamento seleccionado
      const filteredMunicipios = allmunicipios.filter(
        (municipio) => municipio.id_departamento === despacho.departamento
      );
      setMunicipios(filteredMunicipios);

      setTimeout(() => {
        setValue("municipio", despacho.municipio);
      }, 0);
      setValue("minimo_despacho", despacho.minimo_despacho);
      setValue("maximo_despacho", despacho.maximo_despacho);
      setValue("precio_minimo_venta", despacho.precio_minimo_venta);
      setValue("forma_pago", despacho.forma_pago);
      setValue("frecuencia_venta", despacho.frecuencia_venta);
      setValue("entrega_punto_a_punto", despacho.entrega_punto_a_punto);
      setSubStep(1);
    } else {
      console.log("El despacho seleccionado no es valido");
    }
  };

  const eliminarDespacho = (index) => {
    const newDispatchs = despachos.filter((_, i) => i !== index);
    setDespacho(newDispatchs);
    set_despachoSeleccionado(null);
  };


  const handleButtonClick = async () => {
    const fieldsToValidate = [
      "departamento",
      "municipio",
      "minimo_despacho",
      "maximo_despacho",
      "forma_pago",
      "frecuencia_venta",
      "entrega_punto_a_punto",
    ];
  
    // Validar todos los campos
    const validationResults = await trigger(fieldsToValidate);
  
    if (!validationResults) {
      // Encuentra el primer campo con error
      const firstInvalidField = fieldsToValidate.find((field) => errors[field]);
  
      if (firstInvalidField) {
        // Selecciona el elemento del DOM directamente
        const errorElement = document.getElementById(firstInvalidField);
  
        if (errorElement) {
          // Desplaza hasta el elemento
          errorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
  
          // Opcional: Enfoca el campo
          errorElement.focus?.();
        } else {
          console.error(
            `No se encontró el elemento con id: ${firstInvalidField}`
          );
        }
      }
    } else {
      // Si todos los campos son válidos
      agregarDespacho();
      setSubStep(0);
    }
  };
  
  // ------------------FIN LOGICA DE CAMPO SELECT PASO 3------------------

  //********************************INICIO LOGICA DE PASOS**********************************

  const getStepContent = (step) => {
    // ------------------INICIO PASO 1------------------
    return (
      <div className="border-b border-gray-200">
        {/*ENCABEZADO*/}
        <div className="border-b border-gray-200 p-4 flex items-center justify-center relative">
          {step > 0 ? (
            <button
              onClick={handleBack}
              type="button"
              className="absolute left-0 ml-4"
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
          ) : (
            <button
              className="absolute left-0 ml-4"
              type="button"
              onClick={() => window.close()}
            >
              <CloseIcon className="w-5 h-5" />
            </button>
          )}
          <h2 className="text-lg font-medium">
            {step === 2 && subStep === 1
              ? "Agregar despacho"
              : `Paso ${step + 1}`}
          </h2>
          <button
            className="absolute right-0 mr-4"
            type="button"
            // onClick={() => setShowHelpMenu(!showHelpMenu)}
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
          {/* {showHelpMenu && <HelpMenu />} */}
        </div>
        {/*Barra de progreso*/}
        <div className="relative h-2 bg-gray-200">
          <div
            className="absolute h-2 bg-green-600 transition-all duration-300"
            style={{ width: `${((step + 1) / 3) * 100}%` }}
          />
        </div>
        {/*Contenido*/}
        <div className="p-4">
          {/*PASO NUMERO 1*/}
          {step === 0 && (
            <div className="space-y-4">
              <img
                src="/imagenes/Banner_1.png"
                alt="Banner Paso 1"
                className="w-full mb-4"
                // style={{height: "300px"}}
              />
              <Controller
                name="categoria"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth className="mb-4">
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: "#7dca6b", // Color del label cuando está enfocado
                        },
                      }}
                    >
                      Categoría
                    </InputLabel>
                    <Select
                      {...field}
                      label="Categoría"
                      name="categoria"
                      id="categoria"
                      className="select__input"
                      value={idCategoriaSeleccionada}
                      onChange={handleCategoryChange}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            width: 250,
                          },
                        },
                      }}
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7dca6b", // Color del borde cuando está enfocado
                        },
                      }}
                    >
                      {opcionesCategorias.map((categoria) => (
                        <MenuItem key={categoria.id} value={categoria.id}>
                          {categoria.title}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText
                      style={{
                        color: errors?.categoria?.message ? "#d33333" : "",
                      }}
                    >
                      {errors?.categoria?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="nombre_producto"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Nombre del producto"
                    variant="outlined"
                    className="mb-4"
                    name="nombre_producto"
                    id="nombre_producto"
                    error={!!errors.nombre_producto}
                    helperText={errors?.nombre_producto?.message}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#7dca6b", // Color del borde cuando está enfocado
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#7dca6b", // Color del label cuando está enfocado
                        },
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="descripcion"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Descripción"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="descripcion"
                    id="descripcion"
                    error={!!errors.descripcion}
                    helperText={errors?.descripcion?.message}
                    required
                    inputProps={{ maxLength: 600 }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#7dca6b", // Color del borde cuando está enfocado
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#7dca6b", // Color del label cuando está enfocado
                        },
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="unidad_medida"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth className="mb-4">
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: "#7dca6b", // Color del label cuando está enfocado
                        },
                      }}
                    >
                      UND
                    </InputLabel>
                    <Select
                      {...field}
                      label="UND"
                      name="unidad_medida"
                      id="unidad_medida"
                      className="select__input"
                      value={field.value || undStates}
                      onChange={(e) => {
                        field.onChange(e);
                        set_unidadSeleccionada(e.target.value); // Actualizar el estado local
                        setund(e.target.value); // Actualizar el estado global
                        field.onChange(e);
                      }}
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7dca6b", // Color del borde cuando está enfocado
                        },
                      }}
                    >
                      <MenuItem value="und">Und (Unidades)</MenuItem>
                      <MenuItem value="ml">ml (Mililitros)</MenuItem>
                      <MenuItem value="lb">lb (Libras)</MenuItem>
                      <MenuItem value="L">L (Litros)</MenuItem>
                      <MenuItem value="kg">Kg (Kilogramos)</MenuItem>
                      <MenuItem value="gal">gal (Galones)</MenuItem>
                      <MenuItem value="g">g (Gramos)</MenuItem>
                    </Select>

                    <FormHelperText
                      style={{
                        color: errors?.unidad_medida?.message ? "#d33333" : "",
                      }}
                    >
                      {errors?.unidad_medida?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                name="iva"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth className="mb-4">
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: "#7dca6b", // Color del label cuando está enfocado
                        },
                      }}
                    >
                      IVA
                    </InputLabel>
                    <Select
                      {...field}
                      label="IVA"
                      name="iva"
                      id="iva"
                      className="select__input"
                      value={field.value || ivastates}
                      onChange={(e) => {
                        field.onChange(e);
                        setiva(e.target.value); // Actualizar el estado global
                        field.onChange(e);
                      }}
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7dca6b", // Color del borde cuando está enfocado
                        },
                      }}
                    >
                      <MenuItem value="19">19%</MenuItem>
                      <MenuItem value="15">15%</MenuItem>
                      <MenuItem value="0">0%</MenuItem>
                    </Select>

                    <FormHelperText
                      style={{
                        color: errors?.iva?.message ? "#d33333" : "",
                      }}
                    >
                      {errors?.iva?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="ficha_tecnica"
                control={control}
                render={({ field }) => (
                  <Areasubida
                    fileURL={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                      setValue("ficha_tecnica", newValue);
                      // Limpiamos el error manualmente si hay un archivo válido
                      if (newValue) {
                        clearErrors("ficha_tecnica");
                      }
                    }}
                    error={errors.ficha_tecnica?.message}
                    setError={(message) => {
                      setError("ficha_tecnica", { type: "manual", message });
                    }}
                  />
                )}
              />
            </div>
          )}

          {/*PASO NUMERO 2*/}
          {step === 1 && (
            <div className="space-y-4">
              <img
                src="/imagenes/Banner_2.png"
                alt="Banner Paso 2"
                className="w-full mb-4"
              />
              <Controller
                name="fotos"
                control={control}
                defaultValue={[]}
                rules={{ required: "Debe subir al menos una imagen" }}
                render={({ field, fieldState: { error } }) => (
                  <SubirFotos
                    photoURLs={field.value}
                    onChange={(newURLs) => {
                      field.onChange(newURLs);
                      // Desencadena la validación manualmente
                      trigger("fotos");
                    }}
                    error={error?.message}
                    setError={setError} // Pasar setError al componente SubirFotos
                  />
                )}
              />
            </div>
          )}
          {/*PASO NUMERO 3*/}
          {step === 2 && (
            <div className="space-y-4">
              {subStep === 0 ? (
                <div className="grid grid-cols-1">
                  <img
                    src="/imagenes/Banner_3.png"
                    alt="Banner Paso 3"
                    className="w-full mb-4"
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    fontSize={13}
                    gutterBottom
                  >
                    Presione en el boton (agregar despacho), para agregar un
                    nuevo sitio de despacho.
                  </Typography>
                  {despachos.length > 0 && (
                    <Controller
                      name="despachos"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth className="mb-4">
                          <InputLabel
                            sx={{
                              "&.Mui-focused": {
                                color: "#7dca6b", // Color del label cuando está enfocado
                              },
                            }}
                          >
                            Despachos (opcional)
                          </InputLabel>
                          <Select
                            {...field}
                            label="Despachos (opcional)"
                            name="despachos"
                            id="despachos"
                            className="select__input"
                            value={
                              despachoSeleccionado !== null
                                ? despachoSeleccionado
                                : ""
                            }
                            onChange={(e) =>
                              set_despachoSeleccionado(e.target.value)
                            }
                            sx={{
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#7dca6b", // Color del borde cuando está enfocado
                                },
                            }}
                          >
                            {despachos.map((despachos, index) => (
                              <MenuItem key={index} value={index}>
                                {`${
                                  municipioNames[despachos.municipio]
                                }, min: ${despachos.minimo_despacho}, max: ${
                                  despachos.maximo_despacho
                                }${
                                  despachos.precio_minimo_venta
                                    ? `, $${despachos.precio_minimo_venta}`
                                    : ""
                                }`}
                              </MenuItem>
                            ))}
                          </Select>
                          {despachoSeleccionado !== null && (
                            <IconButton
                              onClick={() => liimpiarSelect(null)} // Limpiar la selección
                              style={{
                                position: "absolute",
                                right: 30,
                                top: "30%",
                                transform: "translateY(-60%)",
                                padding: "5px",
                                color: "grey",
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                          {despachoSeleccionado !== null && (
                            <div className="grid grid-cols-2 gap-4">
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginTop: "10px" }}
                                onClick={() =>
                                  editarDespacho(despachoSeleccionado)
                                }
                              >
                                Editar
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginTop: "10px" }}
                                onClick={() =>
                                  eliminarDespacho(despachoSeleccionado)
                                }
                              >
                                Eliminar
                              </Button>
                            </div>
                          )}

                          <FormHelperText
                            style={{
                              color: errors?.categoria?.message
                                ? "#d33333"
                                : "",
                            }}
                          >
                            {errors?.categoria?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  )}
                  {despachoSeleccionado == null && (
                    <Button
                      style={{ color: "#384b2e", padding: "8px" }}
                      sx={{
                        width: "200px",
                        margin: "0 auto",
                        marginTop: "10px",
                        "&:hover": {
                          backgroundColor: "#e7ffe7", // Color de hover personalizado
                        },
                      }} // Ajusta el tamaño del botón
                      startIcon={<AddLocationAltIcon />} // Añade un icono al botón
                      onClick={() => setSubStep(1)}
                    >
                      Agregar Despacho
                    </Button>
                  )}
                </div>
              ) : (
                //----INICIO PASO OCULTO
                <div>
                  <Controller
                    name="departamento"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth className="mb-4">
                        <InputLabel
                          sx={{
                            "&.Mui-focused": {
                              color: "#7dca6b", // Color del label cuando está enfocado
                            },
                          }}
                        >
                          Departamento de venta
                        </InputLabel>
                        <Select
                          {...field}
                          label="Departamento de venta"
                          name="departamento"
                          id="departamento"
                          className="select__input"
                          value={field.value || ""}
                          onChange={(e) => {
                            field.onChange(e);
                            const selectedDepartamento = e.target.value; // Asigna el valor seleccionado a la constante
                            handleDepartmentChange(e);
                            const nuevoMunicipio =
                              selectedDepartamento === "33"
                                ? "170"
                                : nuevoDespacho.municipio;
                            // console.log("Nuevo municipio:", nuevoMunicipio);
                            set_nuevoDespacho({
                              ...nuevoDespacho,
                              departamento: selectedDepartamento,
                              municipio: nuevoMunicipio,
                            });
                            if (selectedDepartamento === "33") {
                              setValue("municipio", "170"); // Asigna automáticamente "Bogotá" al campo municipio
                            }
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                                width: 250,
                              },
                            },
                          }}
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#7dca6b", // Color del borde cuando está enfocado
                            },
                          }}
                        >
                          {departamentos.map((departamentos) => (
                            <MenuItem
                              key={departamentos.id}
                              value={departamentos.id}
                            >
                              {departamentos.nombre}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText
                          style={{
                            color: errors?.departamento?.message
                              ? "#d33333"
                              : "",
                          }}
                        >
                          {errors?.departamento?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />

                  {departamentoSeleccionado &&
                    departamentoSeleccionado !== "33" && ( //Si el departamento seleccionado es diferente a Bogotá se muestra el campo de municipio
                      <Controller
                        name="municipio"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            className="mb-4"
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel
                              sx={{
                                "&.Mui-focused": {
                                  color: "#7dca6b", // Color del label cuando está enfocado
                                },
                              }}
                            >
                              Municipio
                            </InputLabel>
                            <Select
                              {...field}
                              label="Municipio"
                              name="municipio"
                              id="municipio"
                              className="select__input"
                              value={field.value || ""}
                              onChange={(e) => {
                                field.onChange(e);
                                set_nuevoDespacho({
                                  ...nuevoDespacho,
                                  municipio: e.target.value,
                                });

                                // trigger("municipio");
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 300,
                                    width: 250,
                                  },
                                },
                              }}
                              sx={{
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#7dca6b", // Color del borde cuando está enfocado
                                  },
                              }}
                            >
                              {municipios.map((municipios) => (
                                <MenuItem
                                  key={municipios.id}
                                  value={municipios.id}
                                >
                                  {municipios.nombre}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              style={{
                                color: errors?.municipio?.message
                                  ? "#d33333"
                                  : "",
                              }}
                            >
                              {errors?.municipio?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    )}

                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "12px",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                  >
                    Cantidades de despacho:
                  </Typography>
                  <Divider style={{ marginTop: "2px", marginBottom: "16px" }} />
                  <Controller
                    name="minimo_despacho"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={`Minimo: ${unidadSeleccionada}`}
                        variant="outlined"
                        className="mb-4"
                        name="minimo_despacho"
                        id="minimo_despacho"
                        style={{ marginTop: "10px" }}
                        error={!!errors.minimo_despacho}
                        helperText={errors?.minimo_despacho?.message}
                        value={field.value || ""}
                        onChange={(e) => {
                          field.onChange(e);
                          set_nuevoDespacho({
                            ...nuevoDespacho,
                            minimo_despacho: e.target.value,
                          });
                          // trigger("minimo_despacho"); // Disparar validación manualmente
                        }}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#7dca6b", // Color del borde cuando está enfocado
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#7dca6b", // Color del label cuando está enfocado
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="maximo_despacho"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={`Maximo: ${unidadSeleccionada}`}
                        variant="outlined"
                        className="mb-4"
                        name="maximo_despacho"
                        id="maximo_despacho"
                        style={{ marginTop: "10px" }}
                        error={!!errors.maximo_despacho}
                        helperText={errors?.maximo_despacho?.message}
                        value={field.value || ""}
                        onChange={(e) => {
                          field.onChange(e);
                          set_nuevoDespacho({
                            ...nuevoDespacho,
                            maximo_despacho: e.target.value,
                          });
                          // trigger("maximo_despacho"); // Disparar validación manualmente
                        }}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#7dca6b", // Color del borde cuando está enfocado
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#7dca6b", // Color del label cuando está enfocado
                            },
                          },
                        }}
                      />
                    )}
                  />

                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "15px",
                      fontSize: "17px",
                      textAlign: "left",
                    }}
                  >
                    Puedo recibir el pago así:
                  </Typography>

                  <Controller
                    name="forma_pago"
                    control={control}
                    render={({ field }) => (
                      <div id="forma_pago" style={{ textAlign: "left" }}>
                        <CustomCheckboxGroup
                          options={paymentOptions.map((option) => ({
                            ...option,
                            checked: field.value.includes(option.value),
                          }))}
                          onChange={(value, checked) => {
                            let updatedValues;
                            if (value === "60" && checked) {
                              // Seleccionar todas las opciones si se selecciona "60 días"
                              updatedValues = paymentOptions.map(
                                (option) => option.value
                              );
                            }
                            // Deseleccionar "60 días" si se deselecciona
                            else if (value === "60" && !checked) {
                              updatedValues = [];
                            } 
                            else if (value === "30" && checked) {
                              // Deseleccionar todas las opciones si se deselecciona "60 días"
                              updatedValues = [...field.value, "15", "Contra entrega", value]
                            }
                            else if (value === "30" && !checked) {
                              updatedValues = field.value.filter(
                                (v) => v !== "15" && v !== "Contra entrega" && v !== value
                              );
                            }
                            else if(value === "45" && checked){
                              updatedValues = [...field.value, "15","30", "Contra entrega", value]
                            }
                            else if(value === "45" && !checked){
                              updatedValues = field.value.filter(
                                (v) => v !== "15" && v !== "30" && v !== "Contra entrega" && v !== value
                              );
                            }
                            else if(value === "15" && checked){
                              updatedValues = [...field.value,"Contra entrega", value]
                            }
                            else if(value === "15" && !checked){
                              updatedValues = field.value.filter(
                                (v) => v !== "Contra entrega" && v !== value
                              );
                            }
                            
                            else {
                              updatedValues = checked
                                ? [...field.value, value]
                                : field.value.filter((v) => v !== value);
                            }
                            field.onChange(updatedValues);
                            set_nuevoDespacho({
                              ...nuevoDespacho,
                              forma_pago: updatedValues,
                            });
                            // Siempre limpiar errores y revalidar sin depender de la longitud
                            clearErrors("forma_pago");
                            // trigger("forma_pago");
                          }}
                          errors={errors.forma_pago?.message}
                          sx={{
                            "&.Mui-checked": {
                              color: "#7dca6b",
                            },
                          }}
                        />
                      </div>
                    )}
                  />
                  <Divider style={{ marginTop: "2px", marginBottom: "16px" }} />
                  <Controller
                    name="precio_minimo_venta"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Precio mínimo de venta (opcional)"
                        variant="outlined"
                        className="mb-4"
                        name="precio_minimo_venta"
                        id="precio_minimo_venta"
                        style={{ marginTop: "10px" }}
                        error={!!errors.precio_minimo_venta}
                        helperText={errors?.precio_minimo_venta?.message}
                        value={field.value || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          set_nuevoDespacho({
                            ...nuevoDespacho,
                            precio_minimo_venta: value,
                          });
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#7dca6b", // Color del borde cuando está enfocado
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#7dca6b", // Color del label cuando está enfocado
                            },
                          },
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="entrega_punto_a_punto"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <div id="entrega_punto_a_punto" style={{ textAlign: "left", marginTop: "15px" }}>
                        <FormControl
                          component="fieldset"
                          error={!!errors.entrega_punto_a_punto}
                        >
                          <FormLabel
                            component="legend"
                            id="demo-radio-buttons-group-label"
                            style={{ color: "#000" }}
                          >
                            Entrega punto a punto?
                          </FormLabel>
                          <RadioGroup
                            {...field}
                            value={field.value || ""}
                            onChange={(e) => {
                              field.onChange(e);
                              set_nuevoDespacho({
                                ...nuevoDespacho,
                                entrega_punto_a_punto: e.target.value,
                              });
                              // trigger("entrega_punto_a_punto");
                            }}
                            aria-labelledby="demo-radio-buttons-group-label"
                          >
                            <FormControlLabel
                              value="Si"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#7dca6b",
                                    },
                                  }}
                                />
                              }
                              label="Si"
                            />
                            <FormControlLabel
                              value="No"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#7dca6b",
                                    },
                                  }}
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                          {errors.entrega_punto_a_punto && (
                            <FormHelperText>
                              {errors.entrega_punto_a_punto.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    )}
                  />
                  <Divider style={{ marginTop: "2px", marginBottom: "16px" }} />
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "15px",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Seleccione la frecuencia de venta:
                  </Typography>
                  <Controller
                    name="frecuencia_venta"
                    control={control}
                    render={({ field }) => (
                      <div id="frecuencia_venta" style={{ textAlign: "left" }}>
                        <CustomCheckboxGroup
                          options={sales_frequencyOptions.map((option) => ({
                            ...option,
                            checked: field.value.includes(option.value),
                          }))}
                          onChange={(value, checked) => {
                            let updatedValues;
                            if(value === "1" && checked){
                              updatedValues = sales_frequencyOptions.map(
                                (option) => option.value
                              );
                            }else if(value === "1" && !checked){
                              updatedValues = [];
                            }else if(value === "2" && checked){
                              updatedValues = [...field.value, "7","15","30","45","60", value]
                            }else if(value === "2" && !checked){
                              updatedValues = field.value.filter(
                                (v) => v !== "7" && v !== "15" && v !== "30" && v !== "45" && v !== "60" && v !== value
                              );
                            }else if(value === "60" && checked){
                              updatedValues = [...field.value,value]

                            }else if(value === "45" && checked){
                              updatedValues = [...field.value,"60", value]

                            }else if(value === "45" && !checked){
                              updatedValues = field.value.filter(
                                (v) => v !== "60" && v !== value
                              );

                            }else if(value === "30" && checked){
                              updatedValues = [...field.value,"45","60", value]
                            }else if(value === "30" && !checked){
                              updatedValues = field.value.filter(
                                (v) => v !== "45" && v !== "60" && v !== value
                              );
                            }else if(value === "15" && checked){
                              updatedValues = [...field.value,"30","45","60", value]
                            }else if(value === "15" && !checked){
                              updatedValues = field.value.filter(
                                (v) => v !== "30" && v !== "45" && v !== "60" && v !== value
                              );
                            }else if(value === "7" && checked){
                              updatedValues = [...field.value,"15","30","45","60", value]
                            }else if(value === "7" && !checked){
                              updatedValues = field.value.filter(
                                (v) => v !== "15" && v !== "30" && v !== "45" && v !== "60" && v !== value
                              );
                            }
                            
                            else{
                              updatedValues = checked
                              ? [...field.value, value]
                              : field.value.filter((v) => v !== value);
                            }


                            
                            field.onChange(updatedValues);
                            set_nuevoDespacho({
                              ...nuevoDespacho,
                              frecuencia_venta: updatedValues,
                            });
                            // Siempre limpiar errores y revalidar sin depender de la longitud
                            clearErrors("frecuencia_venta");
                            // trigger("frecuencia_venta");
                          }}
                          errors={errors.frecuencia_venta?.message}
                        />
                      </div>
                    )}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: "200px",
                      margin: "20px auto",
                      display: "block",
                      backgroundColor: "#7dca6b",
                    }}
                    onClick={handleButtonClick}
                  >
                    Guardar y regresar
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  //-------------------------------FIN LOGICA DE PASOS----------------------------------

  return (
    <ThemeProvider theme={theme}>
      <Container className="flex justify-center items-center min-h-screen py-8">
        <Box className="max-w-md mx-auto bg-white shadow-lg rounded-lg">
          <FormProvider {...methods}>
            <Box className="mt-4">{getStepContent(activeStep)}</Box>
            <Box
              className="mt-4 flex justify-between"
              style={{ marginTop: "20px" }}
            >
              {subStep !== 1 && (
                <>
                  <Box className="w-full mb-4">
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      style={{
                        backgroundColor: "#7dca6b",
                        color: "#fff",
                        textTransform: "none",
                        width: "300px",
                        borderRadius: "20px",
                        boxShadow: "none",
                        hover: { boxShadow: "" },
                      }}
                      className="p-3"
                    >
                      {activeStep === steps.length - 1
                        ? "Guardar y Finalizar"
                        : "Guardar y Continuar"}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </FormProvider>
        </Box>
      </Container>
      {/* Diálogo de carga */}

      <Dialog open={isLoading} aria-labelledby="loading-dialog-title">
        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={3}
          >
            <CircularProgress sx={{ color: "#7dca6b" }} />
            <Typography variant="body1" style={{ marginLeft: 20 }}>
              Guardando producto...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Diálogo de éxito */}
      <Dialog
        open={showSuccess}
        onClose={handleCloseSuccess}
        aria-labelledby="success-dialog-title"
      >
        <DialogTitle id="success-dialog-title"></DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding={3}
          >
            <CheckCircleIcon
              style={{ fontSize: 80, color: "#4caf50", marginBottom: 10 }}
            />
            <Typography variant="h6" textAlign={"center"}>
              ¡Producto guardado con éxito!
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseSuccess}
            style={{ color: "#4caf50" }}
            sx={{
              "&:hover": {
                backgroundColor: "#e7ffe7", // Color de hover personalizado
              },
            }}
            autoFocus
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default WhatsAppStepperForm2;
